<template>
  <div>
    <div class="content__top">
      <div>
        <Breadcrumbs
          :views="[
            {
              label: 'Valorización de Puestos',
              to: '/valorizacion-de-puestos/valorizacion',
            },
          ]"
          :currentView="{
            label: 'Mapa de Valorización y Puestos',
            to: '/valorizacion-de-puestos/mapa',
          }"
        />
        <h2>Mapa de Sesgo</h2>
      </div>
      <div class="content__buttons">
        <Menu direction="left">
          <template #label>
            <Button type="button" variant="secondary" size="xsmall">
              <unicon
                class="ellipsis"
                name="ellipsis-h"
                fill="currentColor"
                height="16px"
                width="16px"
              />
            </Button>
          </template>
          <template #options>
            <menu-item @click="exportToExcel">
              <unicon
                name="file-download"
                fill="currentColor"
                height="15.5px"
                width="15.5px"
              ></unicon>
              Exportar a Excel
            </menu-item>
          </template>
        </Menu>
      </div>
    </div>

    <div class="table-container" v-if="dataIsLoaded">
      <table ref="table">
        <tr>
          <th rowspan="2">#</th>
          <th rowspan="2">Puesto</th>
          <th rowspan="2">G&eacute;nero</th>
          <th
            v-for="factor in factors"
            :key="factor.id"
            :colspan="factor.subfactors.length"
            class="center"
          >
            {{ factor.name }}
          </th>
        </tr>

        <tr>
          <template v-for="factor in factors">
            <th
              class="second__header"
              v-for="subfactor in factor.subfactors"
              :key="subfactor.id"
              :title="subfactor.name"
            >
              {{ subfactor.name }}
            </th>
          </template>
        </tr>

        <tr>
          <td></td>
          <td>Puestos con Predominancia Masculina</td>
          <td>M</td>
          <template v-for="factor in factors">
            <td v-for="sf in factor.subfactors" :key="sf.id"></td>
          </template>
        </tr>

        <tr v-for="(position, index) in malePositions" :key="position.id">
          <td>{{ index + 1 }}</td>
          <td>{{ position.name }}</td>
          <td>M</td>

          <template
            v-for="factor in position.evaluation ? position.evaluation.evaluation : factors"
          >
            <td v-for="subfactor in factor.subfactors" :key="subfactor.id">
              {{ (subfactor.selected && subfactor.selected.index) || 0 }}
            </td>
          </template>
        </tr>

        <tr>
          <td></td>
          <td>
            <span class="tag tag--blue">Media de Puestos Masculinos</span>
          </td>
          <td></td>
          <template v-for="factor in factorsWithAverageTotals">
            <td v-for="sf in factor.subfactors" :key="sf.id">
              {{ sf.totals.Masculino.totalPoints / sf.totals.Masculino.totalCount || 0 }}
            </td>
          </template>
        </tr>

        <tr>
          <td></td>
          <td>Puestos con Predominancia Femenina</td>
          <td>F</td>
          <template v-for="factor in factors">
            <td v-for="sf in factor.subfactors" :key="sf.id"></td>
          </template>
        </tr>

        <tr v-for="(position, index) in femalePositions" :key="position.id">
          <td>{{ index + 1 }}</td>
          <td>{{ position.name }}</td>
          <td>F</td>

          <template
            v-for="factor in position.evaluation ? position.evaluation.evaluation : factors"
          >
            <td v-for="subfactor in factor.subfactors" :key="subfactor.id">
              {{ (subfactor.selected && subfactor.selected.index) || 0 }}
            </td>
          </template>
        </tr>

        <tr>
          <td></td>
          <td>
            <span class="tag tag--green">Media de Puestos Femeninos</span>
          </td>
          <td></td>
          <template v-for="factor in factorsWithAverageTotals">
            <td v-for="sf in factor.subfactors" :key="sf.id">
              {{ sf.totals.Femenino.totalPoints / sf.totals.Femenino.totalCount || 0 }}
            </td>
          </template>
        </tr>

        <tr>
          <td></td>
          <td>Puestos con Predominancia Neutra</td>
          <td>N</td>
          <template v-for="factor in factors">
            <td v-for="sf in factor.subfactors" :key="sf.id"></td>
          </template>
        </tr>

        <tr v-for="(position, index) in neutralPositions" :key="position.id">
          <td>{{ index + 1 }}</td>
          <td>{{ position.name }}</td>
          <td>F</td>

          <template
            v-for="factor in position.evaluation ? position.evaluation.evaluation : factors"
          >
            <td v-for="subfactor in factor.subfactors" :key="subfactor.id">
              {{ (subfactor.selected && subfactor.selected.index) || 0 }}
            </td>
          </template>
        </tr>

        <tr>
          <td></td>
          <td>
            <span class="tag">Media de Puestos Neutros</span>
          </td>
          <td></td>
          <template v-for="factor in factorsWithAverageTotals">
            <td v-for="sf in factor.subfactors" :key="sf.id">
              {{ sf.totals.Neutro.totalPoints / sf.totals.Neutro.totalCount || 0 }}
            </td>
          </template>
        </tr>

        <tr>
          <td></td>
          <td>Diferencia</td>
          <td></td>
          <template v-for="factor in factorsWithAverageTotals">
            <td v-for="sf in factor.subfactors" :key="sf.id">
              {{
                (sf.totals.Masculino.totalPoints / sf.totals.Masculino.totalCount || 0) -
                (sf.totals.Femenino.totalPoints / sf.totals.Femenino.totalCount || 0)
              }}
            </td>
          </template>
        </tr>

        <tr>
          <td></td>
          <td>G&eacute;nero del Subfactor</td>
          <td></td>
          <template v-for="factor in factorsWithAverageTotals">
            <td v-for="sf in factor.subfactors" :key="sf.id">
              {{
                sf.totals.Masculino.totalPoints / sf.totals.Masculino.totalCount -
                  sf.totals.Femenino.totalPoints / sf.totals.Femenino.totalCount >=
                1
                  ? 'M'
                  : Math.abs(
                      sf.totals.Masculino.totalPoints / sf.totals.Masculino.totalCount -
                        sf.totals.Femenino.totalPoints / sf.totals.Femenino.totalCount,
                    ) >= 1
                  ? 'F'
                  : 'N'
              }}
            </td>
          </template>
        </tr>
      </table>
    </div>

    <LoadingScreen v-else />
  </div>
</template>

<script>
import { utils, writeFile } from 'xlsx';

import { mapActions, mapState } from 'vuex';

import Button from '@/components/buttons/Button.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import LoadingScreen from '@/components/loading/LoadingScreen.vue';
import Menu from '@/components/menu/Menu.vue';
import MenuItem from '@/components/menu/MenuItem.vue';

export default {
  components: {
    Breadcrumbs,
    LoadingScreen,
    Menu,
    MenuItem,
    Button,
  },

  data: () => ({
    isLoading: false,
    dataIsLoaded: false,
  }),

  computed: {
    ...mapState('jobEvaluation', {
      positions: (state) => state.positions,
      evaluations: (state) => state.evaluations,
      factors: (state) => state.factors,
    }),

    malePositions() {
      return this.positions.filter((pos) => pos.genderDistribution.positionGender === 'Masculino');
    },

    femalePositions() {
      return this.positions.filter((pos) => pos.genderDistribution.positionGender === 'Femenino');
    },

    neutralPositions() {
      return this.positions.filter((pos) => pos.genderDistribution.positionGender === 'Neutro');
    },

    factorsWithAverageTotals() {
      return this.factors.map((factor, factorIndex) => {
        const subfactors = factor.subfactors.map((sf, sfIndex) => {
          const totals = this.positions.reduce(
            (acc, { genderDistribution, evaluation }) => {
              acc[genderDistribution.positionGender].totalCount += 1;

              if (!evaluation) return acc;
              console.log(factor.subfactors);
              console.log(evaluation.evaluation[factorIndex].subfactors);
              console.log(sfIndex);
              acc[genderDistribution.positionGender].totalPoints
              += evaluation.evaluation[factorIndex].subfactors[sfIndex].selected.index || 0;

              return acc;
            },
            {
              Masculino: { totalCount: 0, totalPoints: 0 },
              Femenino: { totalCount: 0, totalPoints: 0 },
              Neutro: { totalCount: 0, totalPoints: 0 },
            },
          );

          return { ...sf, totals };
        });

        return {
          ...factor,
          subfactors,
        };
      });
    },
  },

  methods: {
    ...mapActions('jobEvaluation', ['getFactors', 'getEvaluations', 'getPositions']),

    async loadRequiredData() {
      try {
        this.isLoading = true;

        if (!this.positions.length) await this.getPositions();
        if (!this.evaluations.length) await this.getEvaluations();
        if (!this.factors.length) await this.getFactors();

        this.dataIsLoaded = true;

        console.log('Loaded data!');
      } catch (error) {
        console.log(`Something went wrong: ${error}`);
      } finally {
        this.isLoading = false;
      }
    },

    exportToExcel() {
      const workbook = utils.book_new();
      const $table = this.$refs.table;
      const sheet = utils.table_to_sheet($table);
      utils.book_append_sheet(workbook, sheet, 'Mapa de Sesgo');

      writeFile(workbook, 'sesgo.xlsx');
    },
  },

  mounted() {
    this.loadRequiredData();
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  max-width: 100%;
  width: 100%;
  margin-top: 1rem;
  overflow: auto;
}

.content__top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1rem;
}

.content__buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
}

.tag {
  padding: 0.375rem;
  font-size: 0.75rem;
  color: var(--warning-color-600);
  background-color: var(--warning-color-200);
  border-radius: 4px;

  &--blue {
    color: var(--main-color-500);
    background-color: var(--main-color-100);
  }

  &--green {
    color: var(--success-color-500);
    background-color: var(--success-color-100);
  }
}

th {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;

  &.center {
    text-align: center;
  }
}
</style>
